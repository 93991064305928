import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CacheService } from './cache.service';

@Injectable()
export class CacheHttpRequestService {

  constructor(
    private cache: CacheService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler, ttl: number = 10000): Observable<HttpEvent<any>> {

    const reportUrls = [
      'Logs/GetContinuousTankInventoryLog',
      'Logs/BatchDeliveries',
      'Logs/GetContinuousTankDeliveryLog',
      'logs/getsamplecapturelogs',
      'Logs/GetPhaseSeparationPerformanceLogs',
      'logs/getinvoicinglogs',
      'Filters',
      'getdeliveryorders',
      'GetInventoryByDate',
      'GetAllTankLocationsForUser',
    ];

    if (req.method !== 'GET' || reportUrls.some(u => req.url.toLowerCase().includes(u.toLowerCase()))) {
      return next.handle(req);
    }

    let handler = this.cache.get(req.urlWithParams);

    if (handler) {
      return handler;
    }

    handler = next.handle(req).pipe(
      shareReplay(1),
    );

    this.cache.set(req.urlWithParams, handler, ttl);

    return handler;

  }

  clear(): void {
    this.cache.clear();
  }

}
