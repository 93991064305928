import { CaptureDeliveryData } from '@apx-ui/apx-web-api-v1';
import moment from 'moment-timezone';

import { IDeliveryOrderProduct, IOrderDeliveryData } from '../interfaces';
import {
  BatchOrderModel, ContinuousOrderModel,
  DeliveryOrderModel,
  OrderModels,
  StimulationOrderModel,
} from './index';

export class CaptureFactory {
  protected order: OrderModels;

  static create(order: OrderModels): CaptureFactory | null {
    if (order.isDeliveryList()) {
      return new DeliveryCaptureOrder(order as DeliveryOrderModel);
    }

    switch (order.getDeliveryType()) {
      case 'Continuous':
        return new ContinuousCaptureOrder(order as ContinuousOrderModel);
      case 'Batch':
        return new BatchCaptureOrder(order as BatchOrderModel);
      case 'Stimulation':
        return new StimulationCaptureOrder(order as StimulationOrderModel);
      default:
        return null;
    }
  }

  createOrder(captureData: CaptureDeliveryData): IOrderDeliveryData {

    const deliveryProduct: IDeliveryOrderProduct = {
      CapturedVolume: captureData.capturedVolume,
      DilutionPercentage: this.order.getPrimaryProduct().DilutionPercentage,
      InjectionPoint: null,
      LocationId: this.order.getLocationId(),
      LocationName: this.order.getLocationName(),
      OrderId: this.order.getId(),
      ProductId: this.order.getPrimaryProduct().ProductId,
      ProductName: this.order.getPrimaryProduct().ProductName,
      ReconciledVolume: null,
      TankId: this.order.getTankId(),
      TargetVolume: this.order.getTargetVolume(),
      TotalDeliveryVolume: captureData.capturedVolume,
      UofM: this.order.getPrimaryProduct().UofM,
    };

    return {
      CapturedTimeStamp: captureData.capturedTimeStamp ?? null,
      Comment: null,
      CompanySoldTo: null,
      CriticalDate: null,
      CustomerName: this.order.getCustomerName(),
      DeliveryCaptured: this.order.isCompleted(),
      DeliveryStatus: 'Completed',
      DilutionProduct: null,
      DueDate: null,
      EmptyDate: null,
      Field: this.order.getField(),
      Flush: null,
      FlushVolume: null,
      Id: this.order.getId(),
      InjectionPoint: null,
      LocationId: this.order.getLocationId(),
      LocationName: this.order.getLocationName(),
      OrderId: this.order.getId(),
      OrderType: this.order.getDeliveryType(),
      PostTankLevel: null,
      PreTankLevel: null,
      PrimaryProduct: deliveryProduct,
      ReasonId: null,
      Route: this.order.getRoute(),
      SoldTo: null,
      TankId: this.order.getTankId(),
      TankName: null,
      TargetVolume: this.order.getTargetVolume(),
      VolumeUoM: this.order.getVolumeUoM(),
      SiteName: this.order.getSiteName(),
      AssetType: this.order.getAssetType(),
      AssetDescription: this.order.getAssetDescription(),
      AccountCustomTags: this.order.getAccountCustomTags(),
      GeoLocation: null,
      AccountId: this.order.getAccountId(),
      AccountRoles: [],
      TimeZone: captureData.timeZone,
      unitSystemId: this.order.getUnitSystemId(),
    } as any;
  }

}

export class StimulationCaptureOrder extends CaptureFactory {
  constructor(protected override order: StimulationOrderModel) {
    super();
  }

  override createOrder(captureData: CaptureDeliveryData): IOrderDeliveryData {
    return {
      ...super.createOrder(captureData),
      PrimaryProduct: {
        ...super.createOrder(captureData).PrimaryProduct,
        CapturedVolume: captureData.capturedVolume,
        TotalDeliveryVolume: captureData.capturedVolume,
      },
      CapturedTimeStamp: captureData.capturedTimeStamp,
      EmptyDate: this.order.getEmptyDate(),
    };
  }
}

export class BatchCaptureOrder extends CaptureFactory {

  constructor(protected override order: BatchOrderModel) {
    super();
  }

  override createOrder(captureData: CaptureDeliveryData): IOrderDeliveryData {
    return {
      ...super.createOrder(captureData),
      PrimaryProduct: {
        ...super.createOrder(captureData).PrimaryProduct,
        CapturedVolume: captureData.capturedVolume,
        TotalDeliveryVolume: captureData.capturedVolume,
      },
      CapturedTimeStamp: captureData.capturedTimeStamp,
      DueDate: this.order.getDueDate(),
      Flush: this.order.getFlush(),
      InjectionPoint: this.order.getInjectionPoint(),
      FlushVolume:captureData.flushVolume,
    };
  }

}

export class ContinuousCaptureOrder extends CaptureFactory {

  constructor(protected override order: ContinuousOrderModel) {
    super();
  }

  override createOrder(captureData: CaptureDeliveryData): IOrderDeliveryData {
    const convertedTime = moment.tz(this.order.getDeliveryDate(), captureData.timeZone).format();
    const dilutionProdList = this.order.getDilutionProduct().map(dp => {
      return {
        CapturedVolume: captureData.dilutionProduct[dp.ProductId].amount,
        DilutionPercentage: dp.DilutionPercentage,
        InjectionPoint: null,
        LocationId: this.order.getLocationId(),
        LocationName: this.order.getLocationName(),
        OrderId: this.order.getId(),
        ProductId: dp.ProductId,
        ProductName: dp.ProductName,
        ReconciledVolume: null,
        TankId: null,
        TargetVolume: captureData.dilutionProduct[dp.ProductId].total,
        TotalDeliveryVolume: captureData.dilutionProduct[dp.ProductId].amount,
        UofM: dp.UofM,
      };
    });

    return {
      ...super.createOrder(captureData),
      PrimaryProduct: {
        ...super.createOrder(captureData).PrimaryProduct,
        CapturedVolume: captureData.capturedVolume,
        TotalDeliveryVolume: captureData.capturedVolume,
        TargetVolume:
          this.order.getPrimaryProduct().TargetVolume
          || this.order.calcTargetVolume()
          || this.order.getTargetVolume(),
      },
      CriticalDate: this.order.getCriticalDate(),
      CapturedTimeStamp: captureData.capturedTimeStamp ?? convertedTime,
      DilutionProduct: dilutionProdList,
      PostTankLevel: captureData.postTankLevel,
      PreTankLevel: captureData.preTankLevel,
      EmptyDate: this.order.getEmptyDate(),
    };
  }

}

export class DeliveryCaptureOrder extends CaptureFactory {
  constructor(protected override order: DeliveryOrderModel) {
    super();
  }

  override createOrder(captureData: CaptureDeliveryData): IOrderDeliveryData {
    const dilutionProdList = this.order.getDilutionProduct()?.map(dp => {
      return {
        CapturedVolume: captureData.dilutionProduct[dp.ProductId].amount,
        DilutionPercentage: dp.DilutionPercentage,
        InjectionPoint: null,
        LocationId: this.order.getLocationId(),
        LocationName: this.order.getLocationName(),
        OrderId: this.order.getId(),
        ProductId: dp.ProductId,
        ProductName: dp.ProductName,
        ReconciledVolume: null,
        TankId: null,
        TargetVolume: captureData.dilutionProduct[dp.ProductId].total,
        TotalDeliveryVolume: captureData.dilutionProduct[dp.ProductId].amount,
        UofM: dp.UofM,
      };
    });

    return {
      ...super.createOrder(captureData),
      ...this.order.getData(),
      Comment: captureData.Comment,
      PrimaryProduct: {
        ...this.order.getData().PrimaryProduct,
        CapturedVolume: captureData.capturedVolume,
        TotalDeliveryVolume: captureData.capturedVolume,
      },
      PostTankLevel: captureData.postTankLevel,
      PreTankLevel: captureData.preTankLevel,
      CapturedTimeStamp: captureData.capturedTimeStamp,
      FlushVolume:captureData.flushVolume,
      DilutionProduct: dilutionProdList,
      TimeZone: captureData.timeZone,
    };
  }
}
