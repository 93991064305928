export const environment = {
  production: true,
  api_url: 'https://apx-back.ienergy.halliburton.com/APXApi/api',
  common_api_url: 'https://apx-back.ienergy.halliburton.com/APXCommonApi/api',
  api_file_url: 'https://apx-back.ienergy.halliburton.com/APXFileApi/api',
  api_notification_url: 'https://apx-back.ienergy.halliburton.com/APXNotificationApi/api',
  authSettings: {
    authority: 'https://myapps.halliburton.com/oauth2/aus4rj315gsbFMs0b2p7',
    client_id: '0oa6qnbs0e1TnVKI62p7',
    redirect_uri: 'https://apx.ienergy.halliburton.com/authcallback',
    post_logout_redirect_uri: 'https://apx.ienergy.halliburton.com/postlogout',
  },
};
