import { AbstractModel } from '@apx-ui/apx-core';
import { CustomTag, Package } from '@apx-ui/apx-web-api-v1';

import { convertUoM, DELIVERY_MANAGMENT_TYPE, DELIVERY_STATUS, IProduct, STATUS_CLASS, TaskOrder } from '../interfaces';

export class AbstractOrderModel<T extends TaskOrder> extends AbstractModel<T> {

  constructor(protected override data: T, protected packages: Package[]) {
    super(data);
  }

  getId(): string {
    return this.data.Id;
  }

  getTankId(): string {
    return this.data.TankId;
  }

  isDeliveryList(): boolean {
    return !this.data.DeliveryType;
  }

  getOrderType(): string {
    return this.data.DeliveryType ?? (this.data as any).OrderType;
  }

  getDeliveryType(): string {
    return this.data.DeliveryType;
  }

  getAccountId(): string {
    return this.data.AccountId;
  }

  getField(): string {
    return this.data.Field;
  }

  getRoute(): string {
    return this.data.Route;
  }

  getSiteName(): string {
    return this.data.Site;
  }

  getAssetType(): string {
    return this.data.AssetType;
  }

  getAssetDescription(): string {
    return this.data.AssetDescription;
  }

  getAccountCustomTags(): CustomTag[] {
    return this.data.AccountCustomTags;
  }

  getAggregatedCustomTags(): CustomTag[] {
    return this.data.aggregatedCustomTags;
  }

  getTimeZone(): string {
    return this.data.TimeZone;
  }

  getCustomerName(): string {
    return this.data.CustomerName;
  }

  getLocationName(): string {
    return this.data.LocationName;
  }

  getLocationId(): string {
    return this.data.LocationId;
  }

  getPrimaryProduct(): IProduct {
    return this.data.PrimaryProduct;
  }

  getPackageIds(): string[] {
    return this.data.packageIds;
  }

  getPackageTitle(): string {
    return this.data.packageIds?.length
      ? this.data.packageIds.map(id => this.packages?.find(p => p.Id === id)?.Name).filter(p => !!p).join(', ')
      : 'n/a';
  }

  getPrimaryProductTitle(): string {
    return `${this.data.PrimaryProduct.ProductName}: ${this.getTargetVolumeFormatted()} ${this.data.uomName}`;
  }

  getRouteLocationCustomerFieldTitle(): string {
    const items = [
      this.data.Route, this.data.routeOrderIndex, this.data.CustomerName, this.data.Field,
    ].filter(i => !!i);

    return items.join(' ᛫ ');
  }

  getDeliveryStatus(): string {
    return this.data.DeliveryStatus;
  }

  isEditable(): boolean {
    return !(this.data.DeliveryStatus === DELIVERY_STATUS.COMPLETE
      || this.data.DeliveryStatus === DELIVERY_STATUS.ORDER_COMPLETE);
  }

  isPending(): boolean {
    return this.data.DeliveryStatus === DELIVERY_STATUS.PENDING;
  }

  isPendingApproval(): boolean {
    return this.data.DeliveryStatus === DELIVERY_STATUS.PENDING_APPROVAL;
  }

  isOutForDelivery(): boolean {
    return this.data.DeliveryStatus === DELIVERY_STATUS.OUT_OF_DELIVERY;
  }

  isCancelled(): boolean {
    return this.data.DeliveryStatus === DELIVERY_STATUS.CANCELLED;
  }

  isCompleted(): boolean {
    return this.data.DeliveryStatus === DELIVERY_STATUS.COMPLETE
      || this.data.DeliveryStatus === DELIVERY_STATUS.ORDER_COMPLETE;
  }

  getAccountRoles(): string[] {
    return this.data.AccountRoles;
  }

  getOrderStatus(): string {
    switch (this.data.DeliveryStatus) {
      case DELIVERY_STATUS.PENDING:
        return STATUS_CLASS.PENDING;
      case DELIVERY_STATUS.PENDING_APPROVAL:
        return STATUS_CLASS.PENDING_APPROVAL;
      case DELIVERY_STATUS.OUT_OF_DELIVERY:
        return STATUS_CLASS.OUT_FOR_DELIVERY;
      case DELIVERY_STATUS.CANCELLED:
        return STATUS_CLASS.CANCELLED;
      default:
        return this.isCompleted() ? STATUS_CLASS.COMPLETE : '';
    }
  }

  getOrderManagmentType(): string {
    if (this.data.isNonRoutineOrder) {
      return DELIVERY_MANAGMENT_TYPE.OFF_CYCLE_ORDER;
    }

    return DELIVERY_MANAGMENT_TYPE.ROUTINE_ORDER;
  }

  hasComment(): boolean {
    return !!this.data?.Comment?.comment;
  }

  getComment(): string {
    return this.data?.Comment?.comment || '';
  }

  getCommentAuthorId(): string {
    return this.data?.Comment?.authorId || '';
  }

  getCommentAuthorName(): string {
    return this.data?.Comment?.authorName || '';
  }

  getCommentTimeStamp(): string {
    return this.data?.Comment?.timeStamp || '';
  }

  getTargetVolume(): number {
    return this.data.TargetVolume;
  }

  getTargetVolumeFormatted(): number {
    return +(+this.data.TargetVolume).toFixed(2);
  }

  getVolumeUoM(): string {
    return this.data.VolumeUoM;
  }

  getUnitSystemId(): string {
    return this.data.unitSystemId;
  }

  getUomName(): string {
    return this.data.uomName;
  }

  getUomAbbr(): string {
    return this.data.uomAbbr;
  }

  getFlushUofM(): string {
    return this.data.Flush?.uomAbbr;
  }

  getTotalDeliveryVolume(): number {
    return this.data.TotalDeliveryVolume;
  }

  calcTargetVolume(): number {
    return this.calcTargetVolumeBase(this.data.PrimaryProduct);
  }

  calcTargetVolumeBase(product: IProduct): number {
    return +(((product.DilutionPercentage / 100) * this.data.TargetVolume) *
        convertUoM(this.data.VolumeUoM, product.UofM)).toFixed(2);
  }

  getAssignmentDate(): Date {
    return this.data.assignmentDate;
  }

  getAssignmentAssigneeName(): string {
    return this.data.assignmentAssigneeName;
  }

  getAssignmentAssigneeEmail(): string {
    return this.data.assignmentAssigneeEmail;
  }

  isOwner(email: string, na = false): boolean {
    return (!this.data.assignmentAssigneeEmail && na)
      || this.data.assignmentAssigneeEmail?.toLowerCase() === email.toLowerCase();
  }
}
