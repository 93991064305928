/* eslint-disable no-unused-expressions */
import { GridOptions } from '@ag-grid-community/core';
import { noop } from 'rxjs';

import { GridColumn } from '../interfaces';

export class GridOptionsDefaults {
  width?: number;
  minWidth?: number;
  editable?: boolean;
  filter?: string;
  floatingFilter?: boolean;
  resizable?: boolean;
  rowSelection?: string;
  enableFillHandle?: boolean;
  filterParams?: any;
  singleClickEdit?: any;
}

export class GridOptionsBuilder {
  private columnDefs = [];
  private defaultColDef: GridOptionsDefaults = {
    floatingFilter: true,
    resizable: true,
    enableFillHandle: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultToNothingSelected: true,
      newRowsAction: 'keep',
      buttons: ['reset', 'apply'],
    },
  };
  private frameworkComponents = null;
  private components = null;
  private context = null;

  setDefaultColumnWidth(value: number): GridOptionsBuilder {
    this.defaultColDef.width = value;
    return this;
  }

  setFrameworkComponents(value: any): GridOptionsBuilder {
    this.frameworkComponents = value;
    return this;
  }

  setComponents(value: any): GridOptionsBuilder {
    this.components = value;
    return this;
  }

  setContext(value: any): GridOptionsBuilder {
    this.context = value;
    return this;
  }

  setDefaultColumnMinWidth(value: number): GridOptionsBuilder {
    this.defaultColDef.minWidth = value;
    return this;
  }

  setDefaultEditable(value: boolean): GridOptionsBuilder {
    this.defaultColDef.editable = value;
    return this;
  }

  setDefaultEnableFillHandle(value: boolean): GridOptionsBuilder {
    this.defaultColDef.enableFillHandle = value;
    return this;
  }

  setDefaultRowSelection(value: string): GridOptionsBuilder {
    this.defaultColDef.rowSelection = value;
    return this;
  }

  setDefaultFilter(value: string): GridOptionsBuilder {
    this.defaultColDef.filter = value;
    return this;
  }

  setColumnDefs(value: GridColumn): GridOptionsBuilder {
    this.columnDefs.push(value);
    return this;
  }

  setSingleClickEdit(value: any): GridOptionsBuilder {
    this.defaultColDef.singleClickEdit = value;

    return this;
  }

  unshiftColumnDef(value: GridColumn): GridOptionsBuilder {
    const column = this.columnDefs.find(c => c.field === value.field);

    if (!column) {
      this.columnDefs.unshift(value);
    }

    return this;
  }

  startColumnDef(): ColumnBuilder {
    return new ColumnBuilder(this);
  }

  getColumnsDefs(): GridColumn[] {
    return this.columnDefs;
  }

  build(): Partial<GridOptions> {
    return {
      columnDefs: [...this.columnDefs],
      defaultColDef: this.defaultColDef,
      frameworkComponents: this.frameworkComponents,
      components: this.components,
      context: this.context,
      suppressScrollOnNewData: true,
    };
  }

  getFields(): string[] {
    return this.columnDefs.map(c => c.field);
  }

}

export class ColumnBuilder {

  private headerName: string;
  private field: string;
  private cellStyle: any;
  private cellClass: any;
  private filter: string | boolean;
  private editable: any;
  private colId: string;
  private cellRenderer: string;
  private cellRendererFramework: any;
  private cellRendererParams: any;
  private cellEditor: any;
  private cellEditorParams: any;
  private refData: any;
  private width: number;
  private minWidth: number;
  private valueFormatter: any;
  private filterParams: any;
  private sortable: any;
  private comparator: any;

  constructor(private optionsBuilder?: GridOptionsBuilder) {
  }

  setHeaderName(value: string): ColumnBuilder {
    this.headerName = value;
    return this;
  }

  setCellRenderer(value: string): ColumnBuilder {
    this.cellRenderer = value;
    return this;
  }

  setCellRendererFramework(value: any): ColumnBuilder {
    this.cellRendererFramework = value;
    return this;
  }

  setSortable(value: any): ColumnBuilder {
    this.sortable = value;
    return this;
  }

  setDateComparator(value: any): ColumnBuilder {
    this.comparator = value;
    return this;
  }

  setCellRendererParams(value: any): ColumnBuilder {
    this.cellRendererParams = value;
    return this;
  }

  setColId(value: string): ColumnBuilder {
    this.colId = value;
    return this;
  }

  setField(value: string): ColumnBuilder {
    this.field = value;
    return this;
  }

  setEditable(value: any): ColumnBuilder {
    this.editable = value;
    return this;
  }

  setCellStyle(value: any): ColumnBuilder {
    this.cellStyle = value;
    return this;
  }

  setCellClass(value: any): ColumnBuilder {
    this.cellClass = value;
    return this;
  }

  setFilter(value: string | boolean): ColumnBuilder {
    this.filter = value;
    return this;
  }

  setFilterParams(value: any): ColumnBuilder {
    this.filterParams = value;
    return this;
  }

  setCellEditor(value: any): ColumnBuilder {
    this.cellEditor = value;
    return this;
  }

  setCellEditorParams(value: any): ColumnBuilder {
    this.cellEditorParams = value;
    return this;
  }

  setRefData(value: any): ColumnBuilder {
    this.refData = value;
    return this;
  }

  setWidth(value: number): ColumnBuilder {
    this.width = value;
    return this;
  }

  setMinWidth(value: number): ColumnBuilder {
    this.minWidth = value;
    return this;
  }

  setValueFormatter(value: any): ColumnBuilder {
    this.valueFormatter = value;
    return this;
  }

  build(): GridColumn {
    const column: GridColumn = {
      headerName: this.headerName,
    };

    column.cellStyle = this.cellStyle ? this.cellStyle : noop();
    column.filter = this.filter || this.filter === false ? this.filter : 'agTextColumnFilter';

    this.editable || this.editable === false ? column.editable = this.editable : noop();
    this.colId ? column.colId = this.colId : noop();
    this.cellRenderer ? column.cellRenderer = this.cellRenderer : noop();
    this.field ? column.field = this.field : noop();
    this.cellRendererParams ? column.cellRendererParams = this.cellRendererParams : noop();
    this.cellEditor ? column.cellEditor = this.cellEditor : noop();
    this.cellEditorParams ? column.cellEditorParams = this.cellEditorParams : noop();
    this.refData ? column.refData = this.refData : noop();
    this.width ? column.width = this.width : noop();
    this.minWidth ? column.minWidth = this.minWidth : noop();
    this.valueFormatter ? column.valueFormatter = this.valueFormatter : noop();
    this.filterParams ? column.filterParams = this.filterParams : noop();
    this.sortable ? column.sortable = this.sortable : noop();
    this.comparator ? column.comparator = this.comparator : noop();

    return column;
  }

  endColumnDef(): GridOptionsBuilder {
    if (!this.optionsBuilder) {
      throw new Error('Options objet not defined.');
    }

    this.optionsBuilder.setColumnDefs(this.build());
    return this.optionsBuilder;
  }

}
